import React, { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { format } from 'date-fns'; // For date formatting
import "./Kanban.scss";

const KanbanCalendar = ({ getTaskdata, onDateClick }) => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);

  // Utility to format dates consistently
  const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');

  // Set up the events based on getTaskdata
  useEffect(() => {
    if (getTaskdata && getTaskdata.length > 0) {
      const eventsData = getTaskdata.map((task) => ({
        title: task.Name,  // Assuming task.Name is the correct field for title
        start: formatDate(task.date),  // Ensure the date format is consistent
        end: formatDate(task.date),
        extendedProps: { type: "todo" },
        display: "block",
        dotColor: "#0f0", // Customize event appearance
      }));
      setEvents(eventsData);
    }
  }, [getTaskdata]);

  return (
    <div className="kanban-calendar-wrapper">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={(arg) => onDateClick(formatDate(arg.dateStr))} // Pass formatted date on click
        eventContent={(eventInfo) => (
          <div style={{ cursor: "pointer" }}>
            <span
              style={{
                color: "forestgreen",
                marginRight: "5px",
                position: "relative",
                bottom: "32px",
                left: "77px",
              }}
            >
              ●
            </span>
            <b style={{ color: "black" }}>
              {eventInfo.event.title.length > 10
                ? eventInfo.event.title.slice(0, 10) + "..."
                : eventInfo.event.title}
            </b>
          </div>
        )}
        height={"auto"}
        headerToolbar={{ start: "prev", center: "title", end: "next" }}
      />
    </div>
  );
};

export default KanbanCalendar;
