import React, {useState, useEffect, useRef} from "react";
import {Worker, Viewer} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import Draggable from "react-draggable";
import {ResizableBox} from "react-resizable";
import SignatureCanvas from "react-signature-canvas";
import "react-resizable/css/styles.css";
import "./SetupDoc.scss";
import backArrow from "../../assets/Ai-search/back.svg";
import crossIcon from "../../assets/syndicate/svg/cross.svg";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {returnMessages} from "../../store/reducers/message";
import {useParams} from "react-router-dom";
import JSZip from "jszip";
import {CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const SetupDoc = ({selectedData}) => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [fileError, setFileError] = useState("");
  const [uploadPdf, setUploadPdf] = useState(null);
  const [error, setError] = useState(null);
  const {idToken} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {userInformation} = useSelector((state) => state.auth);
  const {id} = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [fetchedPdfFiles, setFetchedPdfFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [signatureData, setSignatureData] = useState(null);
  const signatureCanvasRef = useRef();
  const [signatureLoading, setSignatureLoading] = useState(false);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => file.type === "application/pdf");

    if (validFiles.length > 0) {
      setPdfFiles((prevFiles) => [...prevFiles, ...validFiles]);
      setFileError("");
    } else {
      setFileError("Please select valid PDF files");
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImages([...uploadedImages, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle adding signature
  const handleAddSignature = async () => {
    setSignatureLoading(true);

    try {
      setOpenSignatureDialog(false);
      const pdfContainer = document.querySelector(".contractDetail");
      const canvas = await html2canvas(pdfContainer, {scale: 2});
      const imgData = canvas.toDataURL("image/png");
      const pdfDoc = new jsPDF("p", "mm", "a4");
      pdfDoc.addImage(imgData, "PNG", 0, 0, 210, 297);
      const pdfBlob = pdfDoc.output("blob");

      const formData = new FormData();
      formData.append("editedPDF", pdfBlob, "signed_contract.pdf");

      const config = {
        headers: {
          "auth-token": idToken,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(`/api/Deals/edited_PDF?assetId=${id}`, formData, config);

      dispatch(returnMessages(response.data.message || "Signed contract uploaded successfully.", "success"));
    } catch (error) {
      setError("Failed to upload the signed contract. Please try again.");
    } finally {
      setSignatureLoading(false);
    }
  };

  // Open signature dialog
  const handleSignatureClick = () => {
    setOpenSignatureDialog(true);
  };

  const handleUploadClick = async (pdfToUpload = null) => {
    setLoading(true);
    let filesToUpload = pdfToUpload ? [pdfToUpload] : pdfFiles;

    if (filesToUpload.length === 0) {
      setError("No file selected");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    filesToUpload.forEach((pdfFile) => {
      formData.append("files", pdfFile);
      formData.append("fileNames", pdfFile.name);
    });

    const config = {
      headers: {
        "auth-token": idToken,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(`/api/Deals/Contract_pdfUpload?assetId=${id}`, formData, config);
      setUploadPdf(response?.data);
      setError(null);
      dispatch(returnMessages(response.data.message || "Contracts uploaded successfully.", "success"));
    } catch (error) {
      setError("Please login again, token expired");
    } finally {
      setLoading(false);
    }
  };

  const handlePdfClick = (pdf) => {
    if (userInformation?.roleTypes === 4) {
      setPdfUrl(pdf.url);
    } else {
      setSelectedPdf(pdf);
    }
  };

  useEffect(() => {
    if (userInformation?.roleTypes === 4) {
      const fetchPdf = async () => {
        setLoading(true);
        try {
          const config = {
            headers: {
              "auth-token": idToken,
            },
            responseType: "blob",
          };
          const getZipData = await axios.get(`/api/Deals/get_pdfdata?assetId=${id}`, config);
          const zipBlob = getZipData.data;
          const zip = await JSZip.loadAsync(zipBlob);
          const extractedFiles = [];
          for (const filename in zip.files) {
            const file = zip.files[filename];

            if (!file.dir) {
              const fileBlob = await file.async("blob");
              const fileUrl = URL.createObjectURL(fileBlob);
              extractedFiles.push({
                name: filename,
                url: fileUrl,
              });
            }
          }
          setFetchedPdfFiles(extractedFiles);
          setPdfUrl(extractedFiles[0]?.url);
        } catch (error) {
          console.error("Failed to fetch and unzip the PDF blob:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPdf();
    }
  }, [selectedData, idToken, userInformation?.roleTypes]);

  const handleDeleteDocument = () => {
    if (selectedPdf) {
      const pdfIndex = pdfFiles.findIndex((file) => file === selectedPdf);

      if (pdfIndex !== -1) {
        const updatedPdfFiles = pdfFiles.filter((_, i) => i !== pdfIndex);
        setPdfFiles(updatedPdfFiles);
        if (updatedPdfFiles.length > 0) {
          setSelectedPdf(updatedPdfFiles[0]);
        } else {
          setSelectedPdf(null);
        }
      }
    }
  };

  const truncateFileName = (name) => {
    const words = name.split(" ");
    return words.length > 2 ? words.slice(0, 2).join(" ") + "..." : name;
  };

  const renderDraggableImages = () =>
    uploadedImages.map((imageSrc, index) => (
      <Draggable key={index}>
        <ResizableBox
          width={100}
          height={100}
          minConstraints={[50, 50]}
          maxConstraints={[300, 300]}
        >
          <img
            src={imageSrc}
            alt={`Uploaded ${index}`}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </ResizableBox>
      </Draggable>
    ));

  return (
    <div className="deals-room setupDoc_container">
      <div className="deals-room-left-container setupDocLeftSide_wrapper">
        <div className="actionOptionSelector">
          <button className="backbtn">
            <img
              src={backArrow}
              alt="backArrow"
            />
            Back to Asset Information
          </button>
        </div>

        <div className="contractHandlingBtns">
          {userInformation?.roleTypes === 2 && (
            <div
              className="pdfList_SetupDoc"
              style={{
                marginTop: "20px",
                padding: "10px",
                borderRadius: "5px",
                maxHeight: "200px",
                overflowY: "auto",
              }}
            >
              {pdfFiles.length > 0 &&
                pdfFiles.map((file, index) => (
                  <div
                    key={index}
                    className="pdfItem"
                    onClick={() => handlePdfClick(file)}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      position: "relative",
                      backgroundColor: selectedPdf === file ? "#e0f7fa" : "white",
                      transition: "background-color 0.3s",
                    }}
                  >
                    <p style={{margin: 0}}>{truncateFileName(file.name)}</p>
                  </div>
                ))}
            </div>
          )}
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            style={{display: "none"}}
            id="pdfUpload"
            multiple
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{display: "none"}}
            id="imageUpload"
          />
          {userInformation?.roleTypes === 2 && <button onClick={() => document.getElementById("pdfUpload").click()}>Add Contract(s)</button>}

          {userInformation?.roleTypes === 4 && (
            <div className="pdfList_SetupDoc">
              {loading ? (
                <CircularProgress />
              ) : fetchedPdfFiles.length > 0 ? (
                fetchedPdfFiles?.map((file, index) => (
                  <div
                    key={index}
                    className="pdfItem"
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      position: "relative",
                      backgroundColor: pdfUrl === file.url ? "#E7EFFB" : "white",
                      transition: "background-color 0.3s",
                    }}
                    onClick={() => handlePdfClick(file)}
                  >
                    <p style={{margin: 0}}>{truncateFileName(file.name)}</p>
                  </div>
                ))
              ) : (
                <p>No PDFs available.</p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="center-wrapper setupDocContent_wrapper">
        <div className="contractRenderBox">
          <div className="contractRenderHeader">
            <div className="headerLeftSection">
              <h5>Documentation</h5>
            </div>
            <div className="headerRightSection">
              {selectedPdf && (
                <button onClick={handleDeleteDocument}>
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                  />
                  Delete Document
                </button>
              )}
            </div>
          </div>
          <div className="contractRendering">
            <div
              className="contractDetail"
              style={{position: "relative"}}
            >
              {userInformation?.roleTypes === 2 && selectedPdf && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js">
                  <Viewer fileUrl={URL.createObjectURL(selectedPdf)} />
                  {renderDraggableImages()}
                </Worker>
              )}

              {userInformation?.roleTypes === 4 && pdfUrl && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdfUrl} />
                  {renderDraggableImages()}
                </Worker>
              )}
            </div>
            <div className="contractUploadBtn">
              {userInformation?.roleTypes === 2 && (
                <button
                  onClick={() => handleUploadClick(selectedPdf)}
                  disabled={loading || !selectedPdf}
                >
                  {loading ? <CircularProgress size={20} /> : "Send Contracts"}
                </button>
              )}
              {userInformation?.roleTypes === 2 && (
                <button
                  onClick={() => handleUploadClick()}
                  disabled={loading || pdfFiles.length === 0}
                >
                  {loading ? <CircularProgress size={20} /> : "Send All Contracts"}
                </button>
              )}

              {userInformation?.roleTypes === 4 && (
                <>
                  <button onClick={handleSignatureClick}>Add Signature</button>
                  <button onClick={() => document.getElementById("pdfUpload").click()}>Add Contract(s)</button>
                  <button onClick={() => document.getElementById("imageUpload").click()}>Upload Signature/Image</button>
                </>
              )}
              {userInformation?.roleTypes === 4 && (
                <>
                  <button
                    onClick={handleAddSignature}
                    disabled={signatureLoading}
                  >
                    {signatureLoading ? <CircularProgress size={20} /> : "Send Signed Contract"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openSignatureDialog}
        onClose={() => setOpenSignatureDialog(false)}
      >
        <DialogTitle>Draw Your Signature</DialogTitle>
        <DialogContent>
          <SignatureCanvas
            ref={signatureCanvasRef}
            canvasProps={{width: 500, height: 200, className: "sigCanvas"}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => signatureCanvasRef.current.clear()}>Clear</Button>
          <Button onClick={handleAddSignature}>Add Signature</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SetupDoc;
