import React, {useEffect, useState} from "react";
import add from "../../../assets/svg/add-gray.svg";
import arrowup from "../../../assets/Image/assetDiscovery/arrow_down.svg";
import Checkbox from "../../profile/settings/Checkbox";
import setting from "../../../assets/svg/settings.svg";
import {TaskSidebarList} from "./TaskSidebarList";

export const TaskSidebar = ({handleProject, showAddProject, setShowAddProject}) => {
  const [showProjects, setShowProjects] = useState(false);
  const [showCalendars, setShowCalendars] = useState(false);
  const [showOtherCalendars, setShowOtherCalendars] = useState(false);

  const toggleProjects = () => {
    setShowProjects(!showProjects);
  };

  const toggleCalendars = () => {
    setShowCalendars(!showCalendars);
  };

  const toggleOtherCalendars = () => {
    setShowOtherCalendars(!showOtherCalendars);
  };

  return (
    <div className="task_sidebar">
      <span
        className="hamburger-menu"
        onClick={handleProject}
      >
        <div></div>
        <div></div>
        <div></div>
      </span>
      <div className="task_sidebar_header">
        <span className="title">Projects</span>
        <div>
          <img
            src={add}
            alt="image"
            className="setting_image"
            onClick={() => setShowAddProject(!showAddProject)}
          />
          <img
            className={`arrow-up ${showProjects ? "rotate-up" : ""}`}
            src={arrowup}
            alt="image"
            onClick={toggleProjects}
          />
        </div>
      </div>
      {showProjects && (
        <>
          <TaskSidebarList
            taskCount={6}
            projectName={"Asset Tours"}
            handleProject={handleProject}
          />
          <TaskSidebarList
            taskCount={4}
            projectName={"Brokers"}
            handleProject={handleProject}
          />
          <TaskSidebarList
            taskCount={5}
            projectName={"Asset Tours"}
            handleProject={handleProject}
          />
        </>
      )}
      <div className="task_calendar">
        <div className="task_sidebar_header">
          <span className="title">Calendars</span>
          <div>
            <img
              className="setting_image"
              src={setting}
              alt="image"
            />
            <img
              className={`arrow-up ${showCalendars ? "rotate-up" : ""}`}
              src={arrowup}
              alt="image"
              onClick={toggleCalendars}
            />
          </div>
        </div>
        {showCalendars && (
          <div className="task_calendar_list">
            <Checkbox label={"General Tasks"} />
            <Checkbox label={"Deals"} />
            <Checkbox label={"Recommendations"} />
          </div>
        )}
      </div>
      <div className="task_sidebar_header">
        <span className="title">Other Calendars</span>
        <div>
          <img
            className={`arrow-up ${showOtherCalendars ? "rotate-up" : ""}`}
            src={arrowup}
            alt=""
            onClick={toggleOtherCalendars}
          />
        </div>
      </div>
      {showOtherCalendars && (
        <div className="task_calendar_list">
          <Checkbox label={"Us Holidays"} />
        </div>
      )}
    </div>
  );
};
