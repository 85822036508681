import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  occupiedPercentage: 0,
  leaseRentalGrowth: 0,
  rentGrowth: 0,
  propertyUnitBreakdown: {
    leasedOccupancy: 0,
    physicalOccupancy: 0,
    physicalVacancy: 0,
    totalAvailable: 0,
  },
  buildingsOverview: {
    totalRevenue: "$0.00",
    predictedMonthlyRevenue: "$0.00",
    activeRenters: 0,
    pendingRents: "$0.00",
  },
  topRenters: [],
  marketAnalysis: {
    averageMarketValue: "$0.00",
    totalBaseRent: "$0.00",
    avgBaseRent: "$0.00",
    avgMarketRent: 0,
    avgPastThreeMonths: 0,
    avgCurrentMonth: 0,
    comparisonPastThreeMonths: 0,
    comparisonCurrentMonth: 0,
  },
  leasingSpread: 0,
  totalLeases: 0,
  tenantData: [],
  monthlyStatistics: {
    avgRentPaid: "$0.00",
    avgRentUnpaid: "$0.00",
  },
  leaseMetrics: {
    totalLeasesByMonth: [],
    averageStartYear: 0,
    maxStartDate: "",
    minStartDate: "",
    medianStartDate: "",
    modeStartDate: "",
  },
  latestInvoices: [],
  status: "idle",
  error: null,
};

export const fetchTenantDataMetrics = createAsyncThunk(
  "tenantData/fetchTenantDataMetrics",
  async ({ assetIds, idToken }, thunkAPI) => {
    try {
      const response = await axios.get("api/Portfolio/get_Asset_Dashboard", {
        headers: {
          "auth-token": idToken,
        },
        params: { assetIds },
      });
      const data = response.data;
      return {
        ...data,
        occupiedPercentage: data.occupiedPercentage || initialState.occupiedPercentage,
        leaseRentalGrowth: data.leaseRentalGrowth || initialState.leaseRentalGrowth,
        rentGrowth: data.rentGrowth || initialState.rentGrowth,
        propertyUnitBreakdown: { 
          ...initialState.propertyUnitBreakdown, 
          ...data.propertyUnitBreakdown 
        },
        buildingsOverview: { 
          ...initialState.buildingsOverview, 
          ...data.buildingsOverview 
        },
        topRenters: data.topRenters || [],
        marketAnalysis: { 
          ...initialState.marketAnalysis, 
          ...data.marketAnalysis 
        },
        leasingSpread: data.leasingSpread || initialState.leasingSpread,
        totalLeases: data.totalLeases || initialState.totalLeases,
        tenantData: data.tenantData || [],
        monthlyStatistics: { 
          ...initialState.monthlyStatistics, 
          ...data.monthlyStatistics 
        },
        leaseMetrics: { 
          ...initialState.leaseMetrics, 
          ...data.leaseMetrics 
        },
        latestInvoices: data.latestInvoices || [],
      };
    } catch (error) {
      const errorMsg = error.response?.data || "Network error";
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

const tenantDataSlice = createSlice({
  name: "tenantData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantDataMetrics.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTenantDataMetrics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.occupiedPercentage = action.payload.occupiedPercentage || 0;
        state.leaseRentalGrowth = action.payload.leaseRentalGrowth || 0;
        state.rentGrowth = action.payload.rentGrowth || 0;
        state.propertyUnitBreakdown = action.payload.propertyUnitBreakdown || initialState.propertyUnitBreakdown;
        state.buildingsOverview = {
          ...initialState.buildingsOverview,
          ...action.payload.buildingsOverview,
        };
        state.topRenters = action.payload.topRenters || [];
        state.marketAnalysis = {
          ...initialState.marketAnalysis,
          ...action.payload.marketAnalysis,
        };
        state.leasingSpread = action.payload.leasingSpread || 0;
        state.totalLeases = action.payload.totalLeases || 0;
        state.tenantData = action.payload.tenantData || [];
        state.monthlyStatistics = {
          ...initialState.monthlyStatistics,
          ...action.payload.monthlyStatistics,
        };
        state.leaseMetrics = {
          ...initialState.leaseMetrics,
          ...action.payload.leaseMetrics,
        };
        state.latestInvoices = action.payload.latestInvoices || [];
      })
      .addCase(fetchTenantDataMetrics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export default tenantDataSlice.reducer;
