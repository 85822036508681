import React, {useEffect, useState} from "react";
import arrowup from "../../../assets/Image/assetDiscovery/arrow_down.svg";
import Checkbox from "../../profile/settings/Checkbox";

export const TimeTableBar = ({getTaskdata}) => {
  return (
    <div className="timetable_bar">
      <div className="timetable_bar_header">
        <button>
          <img
            style={{rotate: "90deg"}}
            src={arrowup}
            alt="image"
          />
          Today
          <img
            style={{rotate: "270deg"}}
            src={arrowup}
            alt="image"
          />
        </button>
      </div>
      <div className="timetable_bar_agenda">
        <span className="title">Agenda</span>
        <div className="agenda_list">
          {getTaskdata && getTaskdata?.length > 0 ? (
            getTaskdata?.map((task) => (
              <div key={task?._id}>
                <Checkbox label={task?.Name.length > 10 ? `${task?.Name.substring(0, 10)}...` : task?.Name} />
                <div>
                  <p>{new Date(task?.date).toDateString()}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No tasks available</p>
          )}
        </div>

        {/* <img
            style={{minWidth: "260px", marginLeft: "-12px"}}
            src={timetable}
            alt=""
          /> */}
      </div>
    </div>
  );
};
