import React, {useEffect, useState} from "react";
import eye from "../../../assets/Image/assetDiscovery/eye.png";
 
export const TaskSidebarList = ({projectName, taskCount, handleProject}) => {
  const taskInitials = projectName.slice(0, 2);
  let backgroundColor;
  if (projectName.length <= 5) {
    backgroundColor = "red";
  } else if (projectName.length <= 10) {
    backgroundColor = "#F6AB2F";
  } else {
    backgroundColor = "#009639";
  }

  return (
    <div className="task_sidebar_list">
      <div className="list_left">
        <div
          className="task_initials"
          style={{backgroundColor}}
        >
          {taskInitials}
        </div>
        <div
          className="task_content"
          onClick={handleProject}
        >
          <h3>{projectName}</h3>
          <p>{`${taskCount} tasks`}</p>
        </div>
      </div>

      <img
        src={eye}
        alt="Eye"
      />
    </div>
  );
};

