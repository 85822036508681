
import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, Button, TextField, CircularProgress} from "@mui/material";
import axios from "axios";


export function AddTaskPopup({isOpen, onClose, selectedDate, idToken, userInformation, selectedProperty, getdata}) {
    const [taskTitle, setTaskTitle] = useState("");
    const [taskDate, setTaskDate] = useState(selectedDate);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      setTaskDate(selectedDate);
    }, [selectedDate]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
  
      const taskData = {
        user_id: userInformation?._id,
        Name: taskTitle,
        date: taskDate,
      };
  
      try {
        const config = {
          headers: {
            "auth-token": idToken,
          },
        };
        await axios.post(`/api/Task/addtask`, taskData, config);
        await getdata();
        onClose();
      } catch (err) {
        setError("An Error Occure Please Refresh This Page");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>Add New Task for {selectedDate}</DialogTitle>
        <DialogContent>
          {error && <p style={{color: "red"}}>{error}</p>}
          <form onSubmit={handleSubmit}>
            <TextField
              label="Task Title"
              fullWidth
              margin="normal"
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              required
            />
            <TextField
              label="Task Date"
              type="date"
              fullWidth
              margin="normal"
              value={taskDate}
              onChange={(e) => setTaskDate(e.target.value)}
              required
              InputLabelProps={{shrink: true}}
            />
            <div style={{marginTop: "20px", display: "flex", justifyContent: "flex-end"}}>
              <Button
                onClick={onClose}
                style={{marginRight: "10px"}}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={24}
                      style={{marginLeft: 10, color: "white"}}
                    />
                  </>
                ) : (
                  "Add Task"
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
