import React, {useEffect, useState} from "react";
import "./DealsRoom.scss";
import SvgColorChanger from "../DealsRoom/SvgColorChanger";
import "./Profile.scss";
import dot from "../../assets/svg/dot-green.svg";
import hamburgerIcon from "../../assets/align-justify_3917034.svg";
import {ReactComponent as ProfileSetting} from "../../assets/svg/profile-gray.svg";
import {ReactComponent as Invoice} from "../../assets/svg/invoicing.svg";
import {ReactComponent as Saved} from "../../assets/svg/saved.svg";
import {ReactComponent as Recomm} from "../../assets/svg/bulb.svg";
import {ReactComponent as Clock} from "../../assets/svg/clock.svg";
import profile from "../../assets/profile.png";
import dropdown from "../../assets/dealsroom/svg/dropdown.svg";
import {Link, useNavigate} from "react-router-dom";
import ProfileSettings from "./ProfileSettings";
import Searches from "./saveditems/Searches";
import SavedItems from "./saveditems/SavedItems";
import Fav from "./saveditems/Fav";
import Budget from "./saveditems/Budget";
import Recom from "./recommendation/Recom";
import ViewHistory from "./viewhistory/ViewHistory";
import Invoicing from "./invoicing/Invoicing";
import AdminSettings from "./adminSettings/AdminSettings";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {logout} from "../../store/reducers/authSlice";
import {clearPersistedData} from "../../store/store";
// import { fetchUserDetails } from "../../store/reducers/userDetailsSlice";

const Profile = () => {
  const [isClicked, setIsClicked] = useState(1);
  const [syndicateState, setSyndicate] = useState(1);
  const [loanReqState, setLoanReq] = useState(1);
  const [lenderResponseTab, setLenderResponseTab] = useState(0);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [isSubMenuClicked, setIsSubMenuClicked] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const {userInformation} = useSelector((state) => state.auth);
  const {idToken} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleMenuClicked = (data) => {
    if (data === 13 || data === 23 || data === 19) {
      setIsSubMenuClicked(data);
      setIsClicked(3);
    } else if (data === 3) {
      setIsSubMenuClicked(null);
      setIsClicked(3);
    } else {
      setIsClicked(data);
      setIsSubMenuClicked(null);
    }

    if (data === 4) {
      setLoanReq(1);
    } else if (data === 5) {
      setSyndicate(1);
    }
  };


  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    clearPersistedData();
    navigate("/");
  };

  return (
    <div className="deals-room profile">
      <button
        className="hamburger"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <img
          src={hamburgerIcon}
          alt="Menu"
        />
      </button>

      <div className={`deals-room-left profile_page_sidebar ${isMenuOpen ? "open" : ""}`}>
        <div
          style={{
            height: "216px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0",
          }}
          className="deal-title "
        >
          <div className="left_profile_img">
            {userInformation?.profile_image ? (
              <img
                src={userInformation.profile_image}
                alt="image"
                className="profile-pic"
                style={{borderRadius: "50%",}}
              />
            ) : (
              <img
                src={profile}
                alt="Default Profile"
                className="profile-pic"
                style={{borderRadius: "50%"}}
              />
            )}
          </div>
          <div
            style={{textAlign: "center"}}
            className="deal-left-img-text"
          >
            <span>
              {userInformation?.name + " " + userInformation?.surname}{" "}
              <img
                src={dot}
                alt="image"
              />
            </span>
            <span>{userInformation?.role}</span>
          </div>
        </div>

        <LeftMenu
          icon={<ProfileSetting />}
          name={"Profile Settings"}
          id={1}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Invoice />}
          name={"Invoicing"}
          id={2}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenuDrop
          icon={<Saved />}
          name={"Saved items"}
          id={3}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
          isSubMenuActive={subMenuActive}
        />
        <LeftMenuSort
          name={"Searches"}
          id={13}
          state={isSubMenuClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenuSort
          name={"Favourite Assests"}
          id={23}
          state={isSubMenuClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenuSort
          name={"Budgeting Forecast"}
          id={19}
          state={isSubMenuClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Recomm />}
          name={"Recommendation History"}
          id={4}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <LeftMenu
          icon={<Clock />}
          name={"View History"}
          id={5}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <div className="hr"></div>
        <LeftMenu
          icon={<Clock />}
          name={"Administrator Page"}
          id={6}
          state={isClicked}
          handleMenuClicked={handleMenuClicked}
        />
        <div>
          <div className="setting-data-logout">
            <div className="options">
              <span
                className="option-itembuttton"
                onClick={handleLogout}
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="center-wrapper profile_container">
        {isClicked === 1 && <ProfileSettings />}
        {isClicked === 2 && <Invoicing />}
        {isClicked === 3 && isSubMenuClicked === null && <SavedItems handleMenuClicked={handleMenuClicked} />}
        {isSubMenuClicked === 13 && <Searches />}
        {isSubMenuClicked === 23 && <Fav />}
        {isSubMenuClicked === 19 && <Budget />}
        {isClicked === 4 && <Recom />}
        {isClicked === 5 && <ViewHistory />}
        {isClicked === 6 && <AdminSettings />}
      </div>
    </div>
  );
};

export default Profile;

const LeftMenu = ({icon, name, id, state, handleMenuClicked, isSubMenuActive}) => {
  const isActive = id === state || ([19, 13, 23].includes(id) && isSubMenuActive);

  return (
    <div
      className={`deals-left-menu ${isActive ? "deals-menu-active" : ""}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />
      <span className="left-menu-title">{name} </span>
    </div>
  );
};

const LeftMenuDrop = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu drop ${id === state && "deals-menu-active"}`}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <div>
        <SvgColorChanger svgContent={icon} />
        <span className="left-menu-title">{name} </span>
      </div>
      <img
        src={dropdown}
        alt="image"
      />
    </div>
  );
};

const LeftMenuSort = ({icon, name, id, state, handleMenuClicked}) => {
  return (
    <div
      className={`deals-left-menu left-menu-sort `}
      onClick={() => {
        handleMenuClicked(id);
      }}
    >
      <SvgColorChanger svgContent={icon} />
      <span className={`left-menu-title  ${id === state && "left-menu-sort-active"}`}>{name} </span>
    </div>
  );
};
