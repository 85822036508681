import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import axios from "axios";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Typography, CircularProgress} from "@mui/material";
import {returnMessages} from "../../../store/reducers/message";
import {returnErrors} from "../../../store/reducers/error";

// The InvestmentDialog Component
export const InvestmentDialog = ({open, onClose, investmentData, id, dispatch}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const {idToken} = useSelector((state) => state.auth);

  useEffect(() => {
    if (open) {
      const fetchInvestmentDetails = async () => {
        try {
          const response = await axios.get("/api/GetInvestmentStatus", {
            params: {assetid: id},
            headers: {
              "auth-token": idToken,
            },
          });

          if (response.data.success) {
            setImageUrl(response.data.data?.imageUrl || null);
          }
        } catch (err) {
          dispatch(returnErrors("Error fetching investment status"));
        }
      };

      fetchInvestmentDetails();
    }
  }, [open, id, idToken, dispatch]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      setPreview(URL.createObjectURL(file));
    } else {
      setPreview(null);
    }
    setUploadSuccess(false);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post("/api/UserDetailsWithReadyToInvestimage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "auth-token": idToken,
        },
        params: {
          investmentId: investmentData._id,
        },
      });

      if (response.status === 200) {
        dispatch(returnMessages("File uploaded successfully", 200));
        setUploadSuccess(true);
        setImageUrl(response.data.data.imageUrl);
      }
    } catch (error) {
      dispatch(returnErrors("Upload failed"));
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      const response = await axios.post(
        "/api/paymentConfirmation",
        {
          paymentDetails: {},
        },
        {
          headers: {
            "auth-token": idToken,
          },
          params: {
            investmentId: investmentData._id,
          },
        },
      );

      if (response.data.success) {
        dispatch(returnMessages("Payment confirmed and investment shared", 200));
        onClose();
      }
    } catch (error) {
      dispatch(returnErrors("Payment confirmation failed", error.response?.status || 500));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "12px",
          padding: "20px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DialogTitle style={{textAlign: "center", color: "#6a1b9a", fontWeight: "bold"}}>Investment Details</DialogTitle>
      <DialogContent>
        <DialogContentText style={{textAlign: "center", marginBottom: "20px", fontSize: "16px", color: "#555555"}}>
          Please confirm the investment details and upload the required documents.
        </DialogContentText>
        <Typography style={{marginTop: "15px", color: "#333333"}}>IBAN: {investmentData?.iban}</Typography>
        <Typography style={{marginBottom: "20px", color: "#333333"}}>BIC Code: {investmentData?.bic}</Typography>

        {imageUrl && <DialogContentText style={{color: "green", fontWeight: "bold", textAlign: "center", marginTop: "10px"}}>Payment is confirmed. Image uploaded successfully!</DialogContentText>}
      </DialogContent>

      <DialogContent style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <input
          id="file-button"
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{display: "none"}}
        />
        <label
          htmlFor="file-button"
          style={{
            display: "inline-block",
            padding: "10px 20px",
            backgroundColor: "#6a1b9a",
            color: "#FFFFFF",
            borderRadius: "8px",
            cursor: "pointer",
            textAlign: "center",
            transition: "background-color 0.3s ease",
          }}
        >
          Choose File
        </label>

        {preview && (
          <img
            src={preview}
            alt="Preview"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "8px",
              marginTop: "20px",
              marginBottom: "20px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
      </DialogContent>

      <DialogActions style={{justifyContent: "center", padding: "20px"}}>
        <Button
          onClick={onClose}
          style={{
            border: "1px solid #6a1b9a",
            color: "#6a1b9a",
            marginRight: "10px",
            borderRadius: "5px",
            padding: "10px 20px",
            textTransform: "none",
            fontWeight: "bold",
            width: "30%",
            transition: "background-color 0.3s ease",
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleUpload}
          style={{
            backgroundColor: loading ? "#9c27b0" : "#6a1b9a",
            color: "#FFFFFF",
            borderRadius: "5px",
            padding: "10px 20px", 
            textTransform: "none",
            fontWeight: "bold",
            transition: "background-color 0.3s ease",
            display: imageUrl ? "none" : "inline-block",
            position: "relative",
            width: "160px",
            cursor: loading ? "not-allowed" : "pointer", 
            opacity: loading ? 0.8 : 1, 
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              size={22}
              style={{
                color: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            "Upload"
          )}
        </Button>

        <Button
          onClick={handleConfirm}
          style={{
            backgroundColor: "#764ABC",
            color: "#FFFFFF",
            borderRadius: "5px",
            padding: "10px 20px",
            width: "30%",
            textTransform: "none",
            fontWeight: "bold",
            transition: "background-color 0.3s ease",
          }}
          disabled={!uploadSuccess}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
