import React, {useEffect} from "react";
import "./AssetInformation.scss";
import photo from "../../assets/acqusition/svg/photo.svg";
import location from "../../assets/acqusition/svg/location.svg";
import street from "../../assets/acqusition/svg/street.svg";
import bookmark from "../../assets/acqusition/svg/bookmark.svg";
import share from "../../assets/acqusition/svg/share.svg";
import compare from "../../assets/acqusition/svg/compare.svg";
// import assettype from "../../assets/acqusition/svg/assettype.svg";
import assetype from "../../assets/acqusition/svg/assettype.svg";
import dropdown from "../../assets/acqusition/svg/dropdown.svg";
import file from "../../assets/acqusition/svg/file.svg";
import eye from "../../assets/acqusition/svg/eye.svg";
import utilities from "../../assets/acqusition/img/utilities.png";
import download from "../../assets/acqusition/svg/download.svg";
import prev from "../../assets/acqusition/svg/prev.svg";
import next from "../../assets/acqusition/svg/next.svg";
import assethero from "../../assets/acqusition/img/assethero.png";
import contract from "../../assets/acqusition/img/contract.png";
import landdoc from "../../assets/acqusition/img/landdoc.png";
import n1 from "../../assets/acqusition/img/n1.png";
import n2 from "../../assets/acqusition/img/n2.png";
import n3 from "../../assets/acqusition/img/n3.png";
import n4 from "../../assets/acqusition/img/n4.png";
import n5 from "../../assets/acqusition/img/n5.png";
import n6 from "../../assets/acqusition/img/n6.png";

import map from "../../assets/acqusition/svg/map.svg";
import mappin from "../../assets/acqusition/svg/mappin.svg";
import cross from "../../assets/acqusition/svg/cross.svg";
import walk from "../../assets/acqusition/svg/walk.svg";
import car from "../../assets/acqusition/svg/car.svg";
import train from "../../assets/acqusition/svg/train.svg";
import cycle from "../../assets/acqusition/svg/cycle.svg";
import refresh from "../../assets/acqusition/svg/refresh.svg";
import calender from "../../assets/acqusition/svg/calender.svg";
import building_area from "../../assets/acqusition/svg/building_area.svg";
import total_unit from "../../assets/acqusition/svg/total_unit.svg";
import zoning from "../../assets/acqusition/svg/zoning.svg";
import metro from "../../assets/acqusition/svg/metro.svg";
import market from "../../assets/acqusition/svg/market.svg";
import sub_market from "../../assets/acqusition/svg/sub-market.svg";
import parking from "../../assets/acqusition/svg/parking.svg";
import price from "../../assets/acqusition/svg/price_squart.svg";
import builtgraph from "../../assets/acqusition/svg/bultgraph.svg";
import propertyGraph from "../../assets/acqusition/svg/propertygraph.svg";
import redirect from "../../assets/acqusition/svg/redirect.svg";
import mortgage from "../../assets/acqusition/svg/mortgage.svg";
import profit from "../../assets/acqusition/svg/profit.svg";
import increase from "../../assets/acqusition/svg/increase.svg";
import decrease from "../../assets/acqusition/svg/decrease.svg";
import pricehistory from "../../assets/acqusition/svg/pricehistory.svg";
import star from "../../assets/acqusition/svg/star.svg";
import mapround from "../../assets/acqusition/svg/mapround.svg";
import mall from "../../assets/acqusition/svg/mall.svg";
import show from "../../assets/acqusition/svg/show.svg";
import dropdownbg from "../../assets/acqusition/svg/dropdownbg.svg";
import verified from "../../assets/acqusition/svg/verified.svg";
import twitter from "../../assets/acqusition/svg/twitter.svg";
import love from "../../assets/acqusition/svg/love.svg";
import info from "../../assets/acqusition/svg/info.svg";
import comment from "../../assets/acqusition/svg/comment.svg";
import fillstar from "../../assets/acqusition/svg/fillstar.svg";
import emptystar from "../../assets/acqusition/svg/empty.svg";
import rightarrow from "../../assets/acqusition/svg/rightarrow.svg";
import prevnav from "../../assets/dealsroom/svg/prevnav.svg";
import fwdnav from "../../assets/dealsroom/svg/fwdnav.svg";
import person from "../../assets/acqusition/img/man.png";
import {useState} from "react";
import OffMarkets from "./OffMarketes";
import AssetCard2 from "../DealsRoom/AssectCardData/AssetCard2";
import MaketSurvey from "./MaketSurvey";
import {InputBox, SelectBox} from "../../components/reusableComponents/FormFields/FormFields";
import {Checkmark, CheckmarkTICK} from "../../assets/svg/checkmark";
import DropDown from "../../components/reusableComponents/Dropdown/Dropdown";
import {Checkbox, FormControlLabel, FormGroup, Slider} from "@mui/material";
import {ThumbsSlider} from "../../components/reusableComponents/ThumbsSlider/ThumbsSlider";
import DataPointChart from "./dist/DataPointChart";
import BarChart from "./dist/BarChart";
import HexaChart from "./dist/HexaChart";
import LineChartNew from "./dist/LineChartNew";
import ImageModal from "./ImageEnlarger";
import defaultimage from "../../assets/floor_plan.png";
import {useSelector} from "react-redux";
import {Collapse} from "antd";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress} from "@mui/material";
import {fetchPortfolio} from "../../store/reducers/portfolioSlice";
import {useDispatch} from "react-redux";
import {useParams, useSearchParams} from "react-router-dom";
import {CaretRightOutlined} from "@ant-design/icons";
import axios from "axios";
import PDF_Icon from "../../assets/svg/pdf_4726010.svg";
import {Link} from "react-router-dom";
import {SimilarProperties_list} from "../../utils/Axios";
import {dataforutilities} from "./utilities";
import Upload_Image from "../../assets/svg/Upload_PDF.svg";

const AssetInformation2 = ({selectedData}) => {
  const [navActive, setNavActive] = useState(1);
  const [utiNavActive, setUtiNavActive] = useState(1);
  const [proNavActive, setProNavActive] = useState(1);
  const [expNavActive, setExpNavActive] = useState(1);
  const [mktNavActive, setMktNavActive] = useState(1);
  const [fileName, setFileName] = useState("");
  const [check, setCheck] = useState(false);
  const [builtGraph, setBuiltGraph] = useState(1);
  const [accordian, setAccordian] = useState(1);
  const [countryList, setCountrylist] = useState(["Kindergarden", "Elementarye", "Middle", "High"]);
  const [countryOfCitizenship, setCountryOfCitizenship] = useState("Kindergarden");
  const [countryOfCitizenshipDA, setCountryOfCitizenshipDA] = useState(false);
  const [pricethree, setPricethree] = useState({from: 0, to: 10, any: false});
  const [typeList, setTypeList] = useState(["All types", "Road", "New"]);
  const [typeListSel, setTypeListSel] = useState("All Types");
  const [typeListDa, setTypeListDa] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedSrc, setSelectedSrc] = useState();
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadedPDFs, setUploadedPDFs] = useState([]);
  const dispatch = useDispatch();
  const {id} = useParams();
  const [openpdf, setOpenpdf] = useState(false);
  const {idToken, userInformation} = useSelector((state) => state.auth);
  const [similarProperties, setSimilarProperties] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const onChange = (key) => {};

  // FUNCTION TO DOWNLOAD IMAGE
  const handleDownloadClick = (url) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      const dataUrl = canvas.toDataURL("image/png");

      const anchor = document.createElement("a");
      anchor.style.display = "none";
      anchor.href = dataUrl;
      anchor.download = "image.png";
      document.body.appendChild(anchor);

      anchor.click();

      document.body.removeChild(anchor);
    };
    img.src = url;
  };
  const onDropFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === "application/pdf") {
        onFileChange({target: {files: [file]}});
      } else {
        alert("Please upload a PDF file.");
      }
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const smoothScrollTo = (id) => {
    const targetElement = document.getElementById(id);

    if (targetElement) {
      targetElement.scrollIntoView({behavior: "smooth", block: "start"});
    }
  };

  // PDF Upload
  const onFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : "");
    handleFileSelect(event);
  };

  useEffect(() => {
    dispatch(fetchPortfolio(id));
  }, [dispatch, id]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        event.target.value = "";
        return;
      }
      setSelectedPDF(file);
    }
  };

  const fetchUploadedPDFs = async () => {
    try {
      const response = await axios.get(`api/Portfolio/uploaded_pdfs?assetId=${selectedData?.assetId}`, {
        headers: {
          "auth-token": idToken,
        },
      });
      setUploadedPDFs(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchUploadedPDFs();
  }, []);

  const handleUploadPDF = async () => {
    if (!selectedPDF) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("files", selectedPDF);
    let config = {
      headers: {
        "auth-token": idToken,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(`api/Portfolio/uploadPdf?assetId=${selectedData?.assetId}`, formData, config);

      if (response.status === 200) {
        setSelectedPDF(null);
        fetchUploadedPDFs();
      }
    } catch (error) {
    } finally {
      setLoading(false);
      handleClosePDF();
    }
  };
  // get Utility data
  const [utilities, setUtilities] = useState();

  useEffect(() => {
    if (selectedData?.Property_Code && idToken) {
      const fetchOverview = async () => {
        const propertyCode = selectedData?.Property_Code;
        const response = await axios.get("/api/Portfolio/utility", {
          headers: {"auth-token": idToken},
          params: {propertyCode},
        });

        setUtilities(response.data);
      };

      fetchOverview();
    }
  }, [selectedData?.Property_Code, idToken]);

  const handleClickOpenPDF = () => {
    setOpenpdf(true);
  };

  const handleClosePDF = () => {
    setOpenpdf(false);
    setSelectedPDF(null);
  };
// Floor planning

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setLoading(false);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const filePreviews = files.map((file) => URL.createObjectURL(file));
    setSelectedImages(filePreviews);
  };

  const handleUpload = () => {
    if (selectedFile) {
      setLoading(true);
    }
  };

  const items = [
    {
      key: "1",
      label: "Land documents",
      children: (
        <div className="PDF_Management_Upload">
          <h3>Land documents (2)</h3>
          <Dialog
            open={openpdf}
            onClose={handleClosePDF}
            aria-labelledby="upload-pdf-dialog-title"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle id="upload-pdf-dialog-title">Upload PDF</DialogTitle>
            <DialogContent>
              {fileName && selectedPDF && (
                <div className="pdf-preview">
                  <iframe
                    src={URL.createObjectURL(selectedPDF)}
                    title="PDF Preview"
                    className="PDF_Icon_previews"
                    style={{width: "100%", height: "180px", border: "none"}}
                  />
                  <div>{fileName}</div>
                </div>
              )}
              {loading && (
                <CircularProgress
                  size={24}
                  style={{display: "block", margin: "20px auto"}}
                />
              )}
              <div
                className="drop-area"
                onDrop={onDropFile}
                onDragOver={onDragOver}
              >
                <img
                  src={Upload_Image}
                  alt="Drop icon"
                  className="drop-icon"
                />
                <div className="drop-text">Drop files here</div>
                <div className="file-types">You can upload Only pdf</div>
              </div>
              <label
                htmlFor="file-upload"
                className="file-upload-label"
              >
                <span className="file-upload-button">Choose PDF</span>
                <input
                  id="file-upload"
                  accept="application/pdf"
                  type="file"
                  onChange={onFileChange}
                  className="file-upload-input"
                />
              </label>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClosePDF}
                color="primary"
                disabled={loading}
              >
                Close
              </Button>
              <Button
                onClick={handleUploadPDF}
                color="primary"
                disabled={!selectedPDF || loading}
              >
                Upload
              </Button>
            </DialogActions>
          </Dialog>

          <div className="Land_documents_PDF_flex">
            {uploadedPDFs?.length > 0 ? (
              uploadedPDFs?.map((pdf, index) => (
                <div
                  key={index}
                  className="pdf-card"
                >
                  <iframe
                    src={pdf.url}
                    title={`PDF Preview ${index}`}
                    className="pdf-thumbnail"
                    style={{
                      width: "100%",
                      height: "350px",
                      border: "none",
                      objectFit: "cover",
                    }}
                  />
                  <div className="pdf-details">
                    <span className="pdf-title">{pdf.name}</span>
                  </div>
                </div>
              ))
            ) : (
              <p>No documents uploaded yet.</p>
            )}
          </div>

          {userInformation?.roleTypes === 2 && (
            <Button
              sx={{
                backgroundColor: "#6E50BA",
                color: "#textColor",
                "&:hover": {
                  backgroundColor: "#yourHoverColor",
                },
              }}
              variant="contained"
              onClick={handleClickOpenPDF}
            >
              Upload PDF
            </Button>
          )}
        </div>
      ),
    },
  ];

  // Get Similar Properties
  useEffect(() => {
    const fetchSimilarProperties = async () => {
      let assetId = selectedData?.assetId;
      const result = await SimilarProperties_list(idToken, assetId);
      setSimilarProperties(result.data);
    };

    fetchSimilarProperties();
  }, []);

  // const similarProperties = [
  //   {
  //     id: 1,
  //     name: "Waltham Woods Apartments",
  //     price: "$1,290,000",
  //     image: n1,
  //   },
  //   {
  //     id: 2,
  //     name: "Creekstone Apartments",
  //     price: "$1,100,000",
  //     image: n1,
  //   },
  //   {
  //     id: 3,
  //     name: "The Brook at Columbia",
  //     price: "$1,400,000",
  //     image: n1,
  //   },
  //   // Add more property objects as needed
  // ];

  return (
    <div className="deals-room-center2 asset-center-con asset_modal_container">
      <div className="deals-room-center-header">
        <div className="acq-head-left">
          <div>
            <img
              src={photo}
              alt="image"
            />
            <span>Photos</span>
          </div>
          <div>
            <img
              src={location}
              alt="image"
            />
            <span>Location</span>
          </div>
          <div>
            <img
              src={street}
              alt="image"
            />
            <span>Street View</span>
          </div>
        </div>
        <div className="acq-head-right">
          <div>
            <img
              src={bookmark}
              alt="image"
            />
          </div>
          <div>
            <img
              src={share}
              alt="image"
            />
          </div>
          <div>
            <img
              src={compare}
              alt="image"
            />
          </div>
        </div>
      </div>

      <div className="asset-main-container">
        <div className="asset-main-center">
          <div className="asset-img-hero">
            <ThumbsSlider selectedData={selectedData} />
          </div>
          <div className="deals-room-nav asset-nav ass-nav">
            <span
              className={navActive === 1 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(1);
              }}
            >
              Property Details
            </span>
            <span
              className={navActive === 2 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(2);
                smoothScrollTo("Utilities");
              }}
            >
              Utilities & Amenities
            </span>
            <span
              className={navActive === 3 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(3);
                // smoothScrollTo('')
              }}
            >
              Property History
            </span>
            <span
              className={navActive === 4 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(4);
              }}
            >
              Comps
            </span>

            <span
              className={navActive === 5 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(5);
              }}
            >
              Explore
            </span>

            {/* <span
              className={navActive === 6 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(6);
              }}
            >
              Market Survey
            </span> */}

            {/* <span
              className={navActive === 7 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(7);
              }}
            >
              Market overview
            </span> */}
            {/* <span
              className={navActive === 8 && "deals-room-nav-active ut-nav-active"}
              onClick={() => {
                setNavActive(8);
              }}
            >
              Similar
            </span> */}
          </div>

          <div className="property-d">
            <span>Property Details</span>
          </div>
          <div className="property-wrapper">
            <div className="property-wrapper-left">
              <span>Description</span>
              <span className="property-description">{selectedData?.assetDescription}</span>

              <div className="document-wrapper">
                <Collapse
                  items={items}
                  defaultActiveKey={["1"]}
                  onChange={onChange}
                  accordion
                  expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                />
              </div>

              <div className="divider"></div>
            </div>
            <div className="property-wrapper-right">
              <span>FLOOR PLAN</span>
              <div className="property-img-wrapper">
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[0] || defaultimage}
                  alt="image"
                  style={{height: "90px", width: "90px"}}
                />
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[1] || defaultimage}
                  alt="image"
                  style={{height: "90px", width: "90px"}}
                />
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[2] || defaultimage}
                  alt="image"
                  style={{height: "90px", width: "90px"}}
                />
                <img
                  src={selectedData?.advanceAssets?.asset_info?.property_details?.floor_plan[3] || defaultimage}
                  alt="image"
                  style={{height: "90px", width: "90px"}}
                />
              </div>
              {userInformation?.roleTypes === 2 && (
                <Button
                  variant="contained"
                  // color="primary"
                  className="floorPlan_btn"
                  onClick={handleClickOpen}
                  sx={{
                    backgroundColor: "#6E50BA",
                    color: "#textColor",
                    "&:hover": {
                      backgroundColor: "#yourHoverColor",
                    },
                  }}
                >
                  Add Floor Plan
                </Button>
              )}

              <div className="property-f-wrapper">
                <span className="property-f-title">Property Features</span>
                <PropertyFeatures
                  svgPath={assetype}
                  name={"Asset Type"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.asset_type}
                />
                <PropertyFeatures
                  svgPath={calender}
                  name={"Year Built"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.year_built}
                />
                <div>
                  <PropertyFeatures
                    svgPath={building_area}
                    name={"Building Area"}
                    details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.building_area}
                  />
                  <PropertyFeatures
                    svgPath={total_unit}
                    name={"Total Units"}
                    details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.total_units}
                  />
                  <PropertyFeatures
                    svgPath={zoning}
                    name={"Zoning"}
                    details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.zoning}
                  />
                </div>
                <PropertyFeatures
                  svgPath={metro}
                  name={"Metro"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.metro}
                />
                <PropertyFeatures
                  svgPath={market}
                  name={"Market"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.market}
                />
                <PropertyFeatures
                  svgPath={sub_market}
                  name={"Sub-market"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.sub_market}
                />
                <PropertyFeatures
                  svgPath={parking}
                  name={"Parking"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.parking}
                />
                <PropertyFeatures
                  svgPath={price}
                  name={"Price/sqft"}
                  details={selectedData?.advanceAssets?.asset_info?.property_details?.features?.price_sqft}
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className="utilities"
              id="Utilities"
            >
              <div className="wrapper-heading">Utilities & Amenities</div>
              <div className="deals-room-nav asset-nav ut-nav">
                <span
                  className={utiNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(1);
                  }}
                >
                  Utilities Arrangement
                </span>
                <span
                  className={utiNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(2);
                  }}
                >
                  Amenities
                </span>
                <span
                  className={utiNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(3);
                  }}
                >
                  Jurisdiction
                </span>
                <span
                  className={utiNavActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setUtiNavActive(4);
                  }}
                >
                  Valuation & Taxes
                </span>
              </div>
              <div className={`utilities-main-wrapper ${(utiNavActive == 1 || utiNavActive == 4) && " utilities-w-p"} `}>
                {utiNavActive === 1 && (
                  <div>
                    <div className="table-wrapper">
                      <table className="utility-table">
                        <thead>
                          <tr>
                            <th>UTILITY</th>
                            <th>SERVICE</th>
                            <th>PAID BY</th>
                            <th>METER TYPE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.utils_arrangement?.utils_table?.map((rowData, index) => ( */}
                          {dataforutilities?.map((rowData, index) => (
                            <tr key={index}>
                              <td>{rowData.UTILITY}</td>
                              <td>{rowData.SERVICER}</td>
                              <td>{rowData.PAID_BY}</td>
                              <td>{rowData.METER_TYPE}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <DataPointChart ElectricityData={selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.utils_arrangement?.utils_cost_history_chart} /> */}
                    {/* <img
                      src={utilities}
                      alt="image"
                    /> */}
                  </div>
                )}

                {utiNavActive === 2 && (
                  <div>
                    <div className="amenities-wrapper">
                      <span>Types of Locations</span>
                      <div className="amenities-w-main">
                        {selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.amenities?.types_of_locations?.map((location, index) => (
                          <div>
                            <span>{location}</span>
                          </div>
                        ))}
                      </div>
                      <span>Other</span>
                      <div className="amenities-w-main">
                        {selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.amenities?.other?.map((other, index) => (
                          <div>
                            <span>{other}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {utiNavActive === 3 && (
                  <div className="jurisdiction-wrapper">
                    {[
                      {label: "State", value: selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.jurisdiction?.state},
                      {label: "Tax rate", value: selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.jurisdiction?.tax_rate},
                      {label: "Assessment", value: selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.jurisdiction?.assessment},
                      {label: "Parcel number", value: selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.jurisdiction?.parcel_no},
                    ].map((item, index) => (
                      <div>
                        <span>{item.label}</span>
                        <span>{item.value}</span>
                      </div>
                    ))}
                  </div>
                )}

                {utiNavActive === 4 && (
                  <div className="value-taxes-main">
                    <div className="valuation-taxes-wrapper">
                      <span>Valuation</span>

                      <div className="valuation-taxes-wrapper-main">
                        <div>
                          <div>
                            <img
                              src={calender}
                              alt="image"
                            />
                            <span>Assessed year</span>
                          </div>
                          <span>{selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.valuation_and_taxes?.assessed_year}</span>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div>
                            <img
                              src={mortgage}
                              alt="image"
                            />
                            <span>Assessed amount</span>
                          </div>
                          <span>{selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.valuation_and_taxes?.assessed_amount}</span>
                        </div>
                      </div>
                    </div>
                    <div className="valuation-taxes-wrapper">
                      <span>Taxes</span>

                      <div className="valuation-taxes-wrapper-main">
                        <div>
                          <div>
                            <img
                              src={calender}
                              alt="image"
                            />
                            <span>Tax year</span>
                          </div>
                          <span>{selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.valuation_and_taxes?.tax_year}</span>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div>
                            <img
                              src={profit}
                              alt="image"
                            />
                            <span>Tax amount</span>
                          </div>
                          <span>{selectedData?.advanceAssets?.asset_info?.utils_and_amenities?.valuation_and_taxes?.tax_amount}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="property-history-wrapper">
              <div className="wrapper-heading">Property History</div>
              <div className="deals-room-nav asset-nav table-nav ut-nav">
                <span
                  className={proNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setProNavActive(1);
                  }}
                >
                  Sales History
                </span>
                <span
                  className={proNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setProNavActive(2);
                  }}
                >
                  Price History
                </span>
              </div>

              {proNavActive === 1 && (
                <div className="p-table-wrapper">
                  <table className="p-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Event</th>
                        <th>PRICE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedData?.advanceAssets?.asset_info?.property_history?.sales_history.map((row, index) => (
                        <tr key={index}>
                          <td>{row.date}</td>
                          <td>{row.event}</td>
                          <td>{row.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {proNavActive === 2 && (
                <div className="property-wrapper-main">
                  <div className="price-history-upper">
                    <div className="price-h-card">
                      <div>
                        <span>This Asset</span>
                        <span>Deviation</span>
                      </div>
                      <div>
                        <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.asset_price}</span>
                        <div>
                          <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.asset_deviation}</span>
                          <img
                            src={increase}
                            alt="image"
                          />
                        </div>
                      </div>
                      <div>
                        <span>Aug. 14, 2018</span>
                        <span>Today</span>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="price-h-card">
                      <div>
                        <span>Average Market</span>
                        <span>Deviation</span>
                      </div>
                      <div>
                        <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.market_price}</span>
                        <div>
                          <span>{selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.market_deviation}</span>
                          <img
                            src={decrease}
                            alt="image"
                          />
                        </div>
                      </div>
                      <div>
                        <span>Aug. 14, 2018</span>
                        <span>Today</span>
                      </div>
                    </div>

                    <InputBox
                      type={"date"}
                      placeholder={"7 years"}
                      svgPath={dropdown}
                    />
                  </div>
                  <div>
                    {/* <img
                      src={pricehistory}
                      alt="image"
                    /> */}
                    <LineChartNew data={selectedData?.advanceAssets?.asset_info?.property_history?.price_history?.price_history_chart} />
                  </div>
                </div>
              )}
            </div>
            <div
              className="explore-wrapper"
              id="explore-section"
            >
              <div className="wrapper-heading">Explore</div>
              <div className="deals-room-nav asset-nav table-nav ut-nav">
                <span
                  className={expNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(1);
                  }}
                >
                  Commute time
                </span>
                <span
                  className={expNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(2);
                  }}
                >
                  Educational
                </span>
                <span
                  className={expNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(3);
                  }}
                >
                  Lifestyle
                </span>
                <span
                  className={expNavActive === 4 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setExpNavActive(4);
                  }}
                >
                  Potential Pros & Cons
                </span>
              </div>

              {expNavActive == 1 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={map}
                      alt="image"
                    />
                  </div>
                  <div className="explore-body-left">
                    <h1>Commute Time</h1>
                    <div className="inp">
                      <InputBox
                        conCLass={"explore-input"}
                        label={"Address"}
                        svgPath={cross}
                        name={"Address"}
                        placeholder={"600 N Michigan Ave, Atlantic Cit"}
                      />
                      <img
                        src={mappin}
                        alt="image"
                      />
                    </div>
                    <div
                      className="explore-checkbox"
                      onClick={() => {
                        setCheck(!check);
                      }}
                    >
                      {check ? <CheckmarkTICK /> : <Checkmark />}
                      <h2>Include Traffic</h2>
                    </div>
                    <div className="explore-ways">
                      <span>Way of getting there</span>
                      <div>
                        <img
                          src={walk}
                          alt="image"
                        />
                        <img
                          src={car}
                          alt="image"
                        />
                        <img
                          src={train}
                          alt="image"
                        />
                        <img
                          src={cycle}
                          alt="image"
                        />
                      </div>
                    </div>
                    <span className="explore-para">
                      {" "}
                      <span>45 min</span> by walk to 600 N Michigan Ave,Atlantic City, NJ 08401
                    </span>

                    <div className="explore-refresh">
                      <img
                        src={refresh}
                        alt="image"
                      />
                      <span> Recalculate</span>
                    </div>
                  </div>
                </div>
              )}

              {expNavActive == 2 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt="image"
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={countryList}
                        selectedData={countryOfCitizenship}
                        setSelectedData={setCountryOfCitizenship}
                        dropdownState={countryOfCitizenshipDA}
                        dropdownSetter={setCountryOfCitizenshipDA}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt="image"
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt="image"
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt="image"
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                    </div>
                    <div className="page-nxt-slider">
                      <div className="page-nxt-slider-inner">
                        <div>
                          <img
                            src={prev}
                            alt="image"
                          />
                        </div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>
                          <img
                            src={next}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {expNavActive == 3 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt="image"
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={countryList}
                        selectedData={countryOfCitizenship}
                        setSelectedData={setCountryOfCitizenship}
                        dropdownState={countryOfCitizenshipDA}
                        dropdownSetter={setCountryOfCitizenshipDA}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt="image"
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt="image"
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                      <div className="educational-card">
                        <div>
                          <span>Northwood School</span>
                          <span>1.5 mi</span>
                        </div>
                        <div>
                          <img
                            src={star}
                            alt="image"
                          />
                          <span>4,7</span>
                        </div>
                      </div>
                    </div>
                    <div className="page-nxt-slider">
                      <div className="page-nxt-slider-inner">
                        <div>
                          <img
                            src={prev}
                            alt="image"
                          />
                        </div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>
                          <img
                            src={next}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {expNavActive == 4 && (
                <div className="explore-body-wrapper">
                  <div className="explore-body-right">
                    <img
                      src={mapround}
                      alt="image"
                    />
                  </div>
                  <div className="educational-left">
                    <div>
                      <DropDown
                        label={"Type"}
                        classDataName={"dropdown-height "}
                        data={typeList}
                        selectedData={typeListSel}
                        setSelectedData={setTypeListSel}
                        dropdownState={typeListDa}
                        dropdownSetter={setTypeListDa}
                      />

                      <div>
                        <div className="slider-label">
                          <span>Radius</span>
                        </div>
                        <div className="slider_container">
                          <div className="top_div inputFlexLine">
                            <p></p>
                            <span>{pricethree.from}&nbsp;mi</span>
                          </div>
                          <Slider
                            defaultValue={pricethree.from}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            step={10}
                            min={0}
                            max={100}
                            onChange={(event) => {
                              setPricethree((prev) => ({
                                ...prev,
                                from: event.target.value.toLocaleString(),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="potential">
                      <div className="edu-l">
                        <div className="mall">
                          <img
                            className="ml"
                            src={mall}
                            alt="image"
                          />
                        </div>
                        <img
                          src={show}
                          alt="image"
                        />
                      </div>
                      <div className="edu-r">
                        <div>
                          <span>A new shopping mall is under construction. </span>
                          <img
                            src={dropdownbg}
                            alt="image"
                          />
                        </div>
                        <div>
                          <span>1.5 miles</span>
                          <span>That may bring inconvenience for 1-2 years in this area. However after that it’ll be a perfect location with a brand new crossroad.</span>
                        </div>
                      </div>
                    </div>

                    <div className="deals-room-doc-page-nav center">
                      <div className="page-nav-cont">
                        <img
                          src={prevnav}
                          alt="image"
                        />
                        <span>2 of 10</span>
                        <img
                          src={fwdnav}
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div
              className="market-survey-wrapper"
              name="test1"
              id="test1"
            >
              <div className="market-sur-heading">
                <span>Market Survey</span>
              </div>

              <div className="deals-room-nav asset-nav ut-nav">
                <span
                  className={mktNavActive === 1 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(1);
                  }}
                >
                  Property Details
                </span>
                <span
                  className={mktNavActive === 2 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(2);
                  }}
                >
                  Utilities & Amenities
                </span>
                <span
                  className={mktNavActive === 3 && "deals-room-nav-active ut-nav-active"}
                  onClick={() => {
                    setMktNavActive(3);
                  }}
                >
                  Property History
                </span>
              </div>

              {mktNavActive == 1 && (
                <div className="news-s-wrapper">
                  {" "}
                  <MaketSurvey />
                  <MaketSurvey />
                </div>
              )}

              {mktNavActive === 2 && (
                <div className="social-net-wrapper">
                  <div className="social-n-l">
                    <div className="social-heading">2,365 Posts</div>

                    <div className="social-n-card">
                      <div className="s-n-c-main">
                        <div className="n-card-upper">
                          <div className="n-c-u-l">
                            <img
                              className="ver"
                              src={person}
                              alt="image"
                            />
                            <div>
                              <span>Gregory Ray</span>
                              <span>@Gregory Ray</span>
                            </div>
                            <img
                              className=""
                              src={verified}
                              alt="image"
                            />
                          </div>
                          <div className="n-c-u-r">
                            {" "}
                            <img
                              src={twitter}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="n-card-center">
                          {" "}
                          <span>
                            I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask.
                            I’ve tried all the options under layer mask and it’s not turning out…
                          </span>{" "}
                        </div>
                        <div className="n-card-down">
                          <div>
                            <div>
                              <img
                                src={love}
                                alt="image"
                              />{" "}
                              <span>249</span>
                            </div>
                            <div>
                              {" "}
                              <span>7:48 PM - May 8, 2019</span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src={info}
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider d"></div>
                      <div className="s-n-c-main-down">
                        <div>
                          <img
                            src={comment}
                            alt="image"
                          />
                          <span>110 people are talking about this</span>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={rightarrow}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="social-n-card">
                      <div className="s-n-c-main">
                        <div className="n-card-upper">
                          <div className="n-c-u-l">
                            <img
                              className="ver"
                              src={person}
                              alt="image"
                            />
                            <div>
                              <span>Gregory Ray</span>
                              <span>@Gregory Ray</span>
                            </div>
                            <img
                              className=""
                              src={verified}
                              alt="image"
                            />
                          </div>
                          <div className="n-c-u-r">
                            {" "}
                            <img
                              src={twitter}
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="n-card-center">
                          {" "}
                          <span>
                            I can’t figure out the how to do the mask on the circle and the speaker holes for the life of me. I select them all and then select the circle layer and choose layer mask.
                            I’ve tried all the options under layer mask and it’s not turning out…
                          </span>{" "}
                        </div>
                        <div className="n-card-down">
                          <div>
                            <div>
                              <img
                                src={love}
                                alt="image"
                              />{" "}
                              <span>249</span>
                            </div>
                            <div>
                              {" "}
                              <span>7:48 PM - May 8, 2019</span>
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src={info}
                              alt="image"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider d"></div>
                      <div className="s-n-c-main-down">
                        <div>
                          <img
                            src={comment}
                            alt="image"
                          />
                          <span>110 people are talking about this</span>
                        </div>
                        <div>
                          {" "}
                          <img
                            src={rightarrow}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="social-n-r">
                    <div className="social-filter-heading">Filter Results</div>
                    <div className="show-filter">
                      <span>Show</span>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Facebook"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Twitter"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Instagram"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Linkedin"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                    </div>

                    <div className="show-filter">
                      <span>Date added</span>
                      <InputBox type={"date"} />
                    </div>
                    <div className="show-filter">
                      <span>Sort by</span>
                      <InputBox
                        type={"date"}
                        placeholder={"Last 30 Days"}
                      />
                    </div>
                  </div>
                </div>
              )}
              {mktNavActive === 3 && (
                <div className="social-net-wrapper">
                  <div className="social-n-l asset-review">
                    <div className="social-heading">165 Ratings</div>
                    <div className="divider d"></div>

                    <div className="rating-wrapper">
                      <div className="rating-container">
                        <span>Location</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={emptystar}
                              alt="image"
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Accuracy</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={emptystar}
                              alt="image"
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Noise</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={emptystar}
                              alt="image"
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Activity</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={emptystar}
                              alt="image"
                            />
                            <img
                              src={emptystar}
                              alt="image"
                            />
                          </div>
                          <span>3.2</span>
                        </div>
                      </div>
                      <div className="rating-container">
                        <span>Cleanliness</span>
                        <div>
                          <div>
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={fillstar}
                              alt="image"
                            />
                            <img
                              src={emptystar}
                              alt="image"
                            />
                          </div>
                          <span>4.2</span>
                        </div>
                      </div>
                    </div>
                    <div className="divider d"></div>
                    <div className="person-rating-wrapper">
                      <div className="person-r-upper">
                        <div className="prof">
                          <img
                            src={person}
                            alt="image"
                          />
                          <div>
                            <span>Susie Chapman</span>
                            <span>May 2017</span>
                          </div>
                        </div>
                        <div>
                          <span>Yelp</span>
                          <div>
                            <div>
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={emptystar}
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="person-r-center">
                        <span>
                          You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously build these relationships.
                          Inspiring and fun meetings, all-hands & trips (Summer Camp & Summit). Support system! Everyone cares for your well-being and become family.
                        </span>
                      </div>
                    </div>
                    <div className="divider d net"></div>
                    <div className="person-rating-wrapper">
                      <div className="person-r-upper">
                        <div className="prof">
                          <img
                            src={person}
                            alt="image"
                          />
                          <div>
                            <span>Shon McWu </span>
                            <span>May 2017</span>
                          </div>
                        </div>
                        <div>
                          <span>Yelp</span>
                          <div>
                            <div>
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={fillstar}
                                alt="image"
                              />
                              <img
                                src={emptystar}
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="person-r-center">
                        <span>You are empowered to make decisions and choose your own goals/tasks. Every day you meet so many amazing entrepreneurs as well as continuously.</span>
                      </div>
                    </div>
                  </div>
                  <div className="social-n-r">
                    <div className="social-filter-heading">Filter Results</div>
                    <div className="show-filter">
                      <span>Show</span>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Facebook"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Twitter"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Instagram"
                          />
                        </FormGroup>
                        <span>785 posts</span>
                      </div>
                      <div className="checkbox-con">
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Linkedin"
                          />
                        </FormGroup>
                        <span>3,550 posts</span>
                      </div>
                    </div>

                    <div className="show-filter">
                      <span>Date added</span>
                      <InputBox type={"date"} />
                    </div>
                    <div className="show-filter">
                      <span>Sort by</span>
                      <InputBox
                        type={"date"}
                        placeholder={"Last 30 Days"}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="page-nxt-slider">
                <div className="page-nxt-slider-inner">
                  <div>
                    <img
                      src={prev}
                      alt="image"
                    />
                  </div>
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                  <div>5</div>
                  <div>
                    <img
                      src={next}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="building-wrapper">
              <div className="building-wrapper-left">
                {/* <img
                  src={propertyGraph}
                  alt="image"
                /> */}
                <HexaChart />
              </div>
              <div className="building-wrapper-right">
                <div className="b-w-r-h">
                  <span>How does Waltham Woods Apartments compare to the average similar nearby?</span>
                </div>

                <div className="built-card">
                  <PropertyDetails
                    svgPath={calender}
                    name={"Year Built"}
                    name_={"2 years older."}
                    details={"1966"}
                    details_={"1978 average"}
                  />
                  <div className="built-card-middle">
                    <PropertyDetails
                      svgPath={building_area}
                      name={"Building Area"}
                      name_={"2 years older."}
                      details={"7,670 sqft"}
                      details_={"6,658 sqft average"}
                    />
                    <PropertyDetails
                      svgPath={total_unit}
                      name={"Total Units"}
                      name_={"There are more units for  the same price."}
                      details={"312"}
                      details_={"410 average"}
                    />
                    <PropertyDetails
                      svgPath={zoning}
                      name={"Zoning"}
                      name_={"There's no clear advantage."}
                      details={"C-2"}
                    />
                  </div>
                  <div className="built-card-bottom">
                    <PropertyDetails
                      svgPath={metro}
                      name={"Metro"}
                      details={"Baltimor"}
                    />
                    <PropertyDetails
                      svgPath={market}
                      name={"Market"}
                      details={"Value"}
                    />
                    <PropertyDetails
                      svgPath={sub_market}
                      name={"Sub-market"}
                      details={"Value"}
                    />
                    <PropertyDetails
                      svgPath={parking}
                      name={"Parking"}
                      details={"3 places"}
                    />
                    <PropertyDetails
                      svgPath={price}
                      name={"Price/sqft"}
                      details={"$524"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="nearby-wrapper">
              {/* Similar Properties */}

              <div className="nearby-card-wrapper">
                {similarProperties?.map((property, index) => (
                  <div
                    className="nearby-card"
                    key={index}
                  >
                    <span>Similar In this range nearby</span>
                    <div>
                      <div className="n-c-l"></div>
                      <img
                        src={property?.assetsImages[0]}
                        alt="property"
                        className="property_images"
                      />
                    </div>
                    <div className="n-c-b">
                      <span className="n-c-b-h">{property?.assetName}</span>
                      <div>
                        <span>{property?.assetValue.toLocaleString()}</span>
                        {/* <img
                          src={redirect}
                          alt="redirect"
                        /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <OffMarkets />
          </div>
        </div>
        <div className="asset-main-right">
          <AssetCard2 selectedData={selectedData} />
        </div>
      </div>
      {isOpenModal && (
        <ImageModal
          setIsOpenModal={setIsOpenModal}
          src={selectedSrc}
          alt="property image"
        />
      )}
    </div>
  );
};

export default AssetInformation2;

const PropertyDetails = ({svgPath, name, details, name_, details_}) => {
  return (
    <div className="property-list">
      <div className="p-l-l">
        <img
          src={svgPath}
          alt="image"
        />
        <div>
          <span className="p-l-l-s-f">{name}</span>
          {name_ && <span className="p-l-l-s-l">{name_}</span>}
        </div>
      </div>
      <div className="p-l-r">
        <span className="p-l-r-s-f">{details}</span>
        {details_ && <span className="p-l-r-s-l">{details_}</span>}
      </div>
    </div>
  );
};

const PropertyFeatures = ({svgPath, name, details}) => {
  return (
    <div className="f-w-c">
      <div>
        {" "}
        <img
          src={svgPath}
          alt="image"
        />
        <div>
          <span>{name}</span>
        </div>
      </div>

      <div>
        <span>{details}</span>
      </div>
    </div>
  );
};

const LandDoc = ({selectedData, setSelectedSrc, setIsOpenModal, landDocuments, handleDownloadClick}) => {
  return (
    <div className="land-doc-wrapper">
      {landDocuments?.map((doc) => (
        <div className="land-doc-card">
          <img
            src={doc}
            alt="image"
          />
          <div>
            <img
              src={eye}
              alt="image"
              onClick={() => {
                setSelectedSrc(doc);
                setIsOpenModal(true);
              }}
            />
            {/* <a href={selectedData?.advanceAssets?.asset_info?.property_details?.docs?.land_docs[0]} target="_blank">Download </a> */}
            <img
              src={download}
              alt="image"
              onClick={() => handleDownloadClick(doc)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const AdvancedDoc = ({selectedData, setSelectedSrc, setIsOpenModal, advancedTechniques, handleDownloadClick}) => {
  return (
    <div className="land-doc-wrapper">
      {advancedTechniques?.map((doc) => (
        <div className="land-doc-card">
          <img
            src={doc}
            alt="image"
          />
          {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
          <div>
            <img
              src={eye}
              alt="image"
              onClick={() => {
                setSelectedSrc(doc);
                setIsOpenModal(true);
              }}
            />
            <img
              src={download}
              alt="image"
              onClick={() => handleDownloadClick(doc)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const FinancingDoc = ({selectedData, setSelectedSrc, setIsOpenModal, financingSchemes, handleDownloadClick}) => {
  return (
    <div className="land-doc-wrapper">
      {financingSchemes?.map((doc) => (
        <div className="land-doc-card">
          <img
            src={doc}
            alt="image"
          />
          {/* <span>Council Decision No. 135/1969. On the registra...</span> */}
          <div>
            <img
              src={eye}
              alt="image"
              onClick={() => {
                // setSelectedSrc('https://ohiostate.pressbooks.pub/app/uploads/sites/160/h5p/content/5/images/image-5bd08790e1864.png')
                setSelectedSrc(doc);
                setIsOpenModal(true);
              }}
            />

            <img
              src={download}
              alt="image"
              onClick={() => handleDownloadClick(doc)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
