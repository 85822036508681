import React, {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../store/reducers/authSlice";
import {Link, useNavigate} from "react-router-dom";
import {InputBox} from "../../components/reusableComponents/FormFields/FormFields";
import {PrimaryButton, PrimaryButtonloading} from "../../components/reusableComponents/Buttons/Buttons";
import lilypandsLogo from "../../assets/svg/lilipadsLogomain.svg";
import "./Signin.scss";
import {returnErrors} from "../../store/reducers/error";
import {returnMessages} from "../../store/reducers/message";

const Signin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });

  var id = useSelector((state) => state.auth?.userInformation?._id);
  var roleTypes = useSelector((state) => state.auth?.userInformation?.roleTypes);

  useEffect(() => {
    if (isAuthenticated && id && roleTypes !== undefined) {
      if (roleTypes === 2) {
        navigate(`/${id}/portfolio`);
      } else if (roleTypes === 4) {
        navigate(`/${id}/asset-discovery?cf=true`);
      } else if (roleTypes === 3) {
        navigate(`/${id}/asset-discovery`);
      } else {
        navigate(`/${id}/asset-discovery`);
      }
    }
  }, [isAuthenticated, id, roleTypes, navigate]);

  const handleLogIn = async () => {
    setLoading(true);
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userLogin.email)) {
        setLoading(false);
        dispatch(returnErrors("Invalid email format", 400));
        return;
      }

      const response = await axios.post("/api/auth/login", userLogin);
      const {success, message, data} = response.data;

      if (success) {
        dispatch(returnMessages(message, 200));
        dispatch(login({token: data.token, userInformation: data.userDetails}));
      } else {
        dispatch(returnErrors(message, 400));
      }
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const {message} = error.response.data;
        dispatch(returnErrors(message || "Something Went Wrong", error.response.status));
      } else if (error.request) {
        dispatch(returnErrors("No response from server", 500));
      } else {
        dispatch(returnErrors("Something Went Wrong", 400));
      }
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogIn();
    }
  };

  return (
    <div className="signupMainCon">
      <div className="signupCon-L">
        <div className="signupScroller">
          <div className="signupFirstSection signupsection">
            <div className="top_header">
              <img
                src={lilypandsLogo}
                alt=""
              />
              <div className="text_wrapper">
                <h1>Welcome to Lilypads</h1>
                <p>Commercial Real Estate Industry’s first artificial intelligence decision engine</p>
              </div>
            </div>
            <div
              className="sigindetails"
              onKeyPress={handleKeyPress}
              tabIndex="0"
            >
              <h1>Sign In</h1>
              <InputBox
                label="E-mail address"
                type="email"
                value={userLogin.email}
                onChange={(e) => setUserLogin((prev) => ({...prev, email: e.target.value}))}
              />
              <InputBox
                label="Password"
                type="password"
                value={userLogin.password}
                onChange={(e) => setUserLogin((prev) => ({...prev, password: e.target.value}))}
              />
              <PrimaryButtonloading
                name={loading ? "Signing in..." : "Sign in"}
                onClick={handleLogIn}
                disabled={loading}
                loading={loading}
                classNames="sigin-button"
              />
            </div>
            <Link to="/password-recovery">
              <h3>Forgot password?</h3>
            </Link>
            <div className="spacerLine30"></div>
            <Link to="/">
              <PrimaryButton
                classNames="grey noMargin"
                name="Create Account!"
                onClick={() => {}}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="signupCon-R">
        <div className="blackoverlay">
          <div className="signin_svg_wrapper">{/* <img src={singinRight} alt="" /> */}</div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
