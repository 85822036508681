import React, { useEffect, useState } from "react";
import "./Task.scss";
import ProjectsAndCalendar from "./ProjectsAndCalendar";
import img from "../../assets/Image/assetDiscovery/general-bg.png";
import TaskModal from "./TaskModal";
import { useSelector } from "react-redux";
import axios from "axios";
import { AddProject } from "./TaskModules/AddProject";
import { AddTaskPopup } from "./TaskModules/AddTaskPopup";
import { Calendar } from "./TaskModules/Calendar";
import { TaskDetailsModal } from "./TaskModules/TaskDetailsModal";
import { TimeTableBar } from "./TaskModules/TimeTableBar";
import { TaskSidebar } from "./TaskModules/TaskSidebar";
import { TaskSidebarList } from "./TaskModules/TaskSidebarList";
import { format } from 'date-fns';

function Task() {
  const [renderKey, setRenderKey] = useState(1);
  const [showAddProject, setShowAddProject] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [project, setProject] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const { idToken } = useSelector((state) => state.auth);
  let [getTaskdata, setTaskData] = useState();
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isAddTaskPopupOpen, setAddTaskPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const { selectedProperty } = useSelector((state) => state.portfolio);
  const { userInformation } = useSelector((state) => state.auth);
  const [selectedTask, setSelectedTask] = useState(null);
  const [projects, setProjects] = useState([
    { id: 1, title: "Project 1", img: img, status: "open", date: "2023-01-15" },
    { id: 2, title: "Project 2", img: "", status: "in-progress", date: "2023-03-22" },
    { id: 3, title: "Project 3", img: img, status: "closed", date: "2023-05-10" },
    { id: 4, title: "Project 4", img: "", status: "open", date: "2023-07-07" },
    { id: 5, title: "Project 5", img: img, status: "in-progress", date: "2023-09-18" },
    { id: 6, title: "Project 6", img: "", status: "closed", date: "2023-11-02" },
    { id: 7, title: "Project 7", img: img, status: "open", date: "2023-02-14" },
    { id: 8, title: "Project 8", img: "", status: "in-progress", date: "2023-04-30" },
    { id: 9, title: "Project 9", img: img, status: "closed", date: "2023-08-05" },
    { id: 10, title: "Project 10", img: "", status: "open", date: "2023-12-20" },
  ]);

  const handleProject = () => {
    setProject(!project);
  };

  const openTaskModal = (columnStatus) => {
    setTaskModalOpen(true);
    setSelectedColumn(columnStatus);
  };

  const closeTaskModal = () => {
    setTaskModalOpen(false);
    setSelectedTask(null);
  };

  console.log("selectedTask", selectedTask);

  const [columns, setColumns] = useState(() => {
    const columnsData = localStorage.getItem("columns");
    return columnsData
      ? JSON.parse(columnsData)
      : [
          { name: "open", border: "#ffffff", projects: projects.filter((project) => project.status === "open") },
          { name: "in-progress", border: "#34AA44", projects: projects.filter((project) => project.status === "in-progress") },
          { name: "closed", border: "#6758F3", projects: projects.filter((project) => project.status === "closed") },
        ];
  });

  useEffect(() => {
    localStorage.setItem("columns", JSON.stringify(columns));
  }, [columns]);

  const handleTaskSubmission = (taskTitle, taskDate, status) => {
    const newTask = {
      id: projects.length + 1,
      title: taskTitle,
      date: taskDate,
      completed: false,
      img: "",
      status: selectedColumn || "open",
    };

    setProjects((prevProjects) => [...prevProjects, newTask]);
    closeTaskModal();
    setRenderKey((prevKey) => prevKey + 1);

    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      const targetColumn = updatedColumns.find((column) => column.name === selectedColumn);

      if (targetColumn) {
        targetColumn.projects.push(newTask);
      }

      return updatedColumns;
    });
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleDateClick = (dateStr) => {
    setSelectedDate(dateStr);
    
    const formattedDateStr = format(new Date(dateStr), 'yyyy-MM-dd');

    const foundTask = getTaskdata?.find((task) => {
      const formattedTaskDate = format(new Date(task?.date), 'yyyy-MM-dd');
      return formattedTaskDate === formattedDateStr;
    });

    if (foundTask) {
      setSelectedTask(foundTask);
      setTaskModalOpen(true);
    } else {
      setAddTaskPopupOpen(true);
    }
  };

  const getdata = async () => {
    if (!userInformation?._id) {
      return;
    }

    let config = {
      headers: {
        "auth-token": idToken,
      },
    };

    let getalldata = await axios.get(`api/Task/gettask?userId=${userInformation?._id}`, config);

    if (getalldata?.data?.tasks) {
      setTaskData(getalldata.data.tasks);
      console.log("Fetched task data:", getalldata.data.tasks);
    } else {
      setTaskData([]);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      {!project ? (
        <div className="task">
          <span className={`hamburger-menu ${showSidebar ? "active" : ""}`} onClick={toggleSidebar}>
            <div></div>
            <div></div>
            <div></div>
          </span>
          <TaskSidebar handleProject={handleProject} showAddProject={showAddProject} setShowAddProject={setShowAddProject} className={showSidebar ? "show" : ""} />
          <div className="Task-flex">
            <Calendar
              openTaskModal={openTaskModal}
              tasksList={projects}
              setAddTaskPopupOpen={setAddTaskPopupOpen}
              handleDateClick={handleDateClick}
              getTaskdata={getTaskdata}
            />

            <TimeTableBar getTaskdata={getTaskdata} />
            <AddTaskPopup
              isOpen={isAddTaskPopupOpen}
              onClose={() => setAddTaskPopupOpen(false)}
              selectedDate={selectedDate}
              idToken={idToken}
              selectedProperty={selectedProperty}
              userInformation={userInformation}
              getdata={getdata}
            />

            {selectedTask && (
              <TaskDetailsModal isOpen={isTaskModalOpen} onClose={closeTaskModal} task={selectedTask} />
            )}
          </div>
        </div>
      ) : (
        <ProjectsAndCalendar
          columns={columns}
          setColumns={setColumns}
          key={renderKey}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          openTaskModal={openTaskModal}
          closeTaskModal={closeTaskModal}
          projectActive={project}
          handleProject={handleProject}
          projects={projects}
          setProjects={setProjects}
        />
      )}
      {showAddProject && <AddProject showAddProject={showAddProject} setShowAddProject={setShowAddProject} />}

      <TaskModal selectedColumn={selectedColumn} isOpen={isTaskModalOpen} onClose={closeTaskModal} onSubmit={(title, date, selectedColumn) => handleTaskSubmission(title, date, selectedColumn)} />
    </>
  );
}

export default Task;
