import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import {Table} from "antd";
import LoaderOverlay from "../../../../components/reusableComponents/Loader";
import "./Maintenance.scss";
import Endpoint from "../../../../utils/Endpoint";

const Maintenance = ({selectedItem}) => {
  const {idToken} = useSelector((state) => state.auth);
  const userPermissions = useSelector((state) => state.portfolio.assetPermissions);
  const {userInformation} = useSelector((state) => state.userInfo);
  let {selectedProperty} = useSelector((state) => state.portfolio);

  const [pieChart, setPieChart] = useState({
    series: [],
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
      },
      itemMargin: {
        vertical: 0,
      },
    },
    labels: ["Under 24h", "24h to 48h", "Over 48h"],
    colors: ["#00A76D", "#FFD700", "#FF6347"], 
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
   
  });
  

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: [],
      fontSize: "10px",
      background: "black",
    },
    colors: ["#1665D8"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

  const hasEditPermission = (section, permission) => {
    if (!Array.isArray(userPermissions)) return false;
    const foundPermission = userPermissions.find((perm) => perm.section === section);
    return foundPermission ? foundPermission.permissions[permission] === true : false;
  };

  const hasMaintenancePermission = userInformation?.roleTypes === 2 || hasEditPermission("Property Data Overview", "Maintenance");

  useEffect(() => {
    const fetchData = async (page = 1, pageSize = 10) => {
      try {
        if (!selectedItem || !selectedItem._id) {
          setLoading(false);
          return;
        }

        const config = {
          headers: {
            "auth-token": idToken,
          },
          params: {
            propertyCode: selectedProperty?.Property_Code,
            page: page,
            limit: pageSize,
          },
        };

        const response = await axios.get(Endpoint.getallmaintance, config);

        if (response && response.data) {
          const {maintenanceData, totalUnits, categoryCounts, completionTimePercentages} = response.data;
          setTableData(maintenanceData);
          setPagination({
            current: page,
            pageSize: pageSize,
            total: totalUnits || 0,
          });

          if (categoryCounts && Object.keys(categoryCounts).length > 0) {
            const categories = Object.keys(categoryCounts);
            const seriesData = Object.values(categoryCounts);

            setChartOptions((prevOptions) => ({
              ...prevOptions,
              xaxis: {
                ...prevOptions.xaxis,
                categories: categories,
              },
            }));

            setChartSeries([{data: seriesData}]);
          }
          if (completionTimePercentages) {
            setPieChart((prevPieChart) => ({
              ...prevPieChart,
              series: [parseFloat(completionTimePercentages.under24hrs), parseFloat(completionTimePercentages.between24and48hrs), parseFloat(completionTimePercentages.over48hrs)],
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (idToken && selectedItem && selectedItem._id) {
      fetchData(pagination.current, pagination.pageSize);
    }
  }, [idToken, selectedItem, pagination.current, pagination.pageSize, selectedProperty]);

  const columns = [
    {
      title: "Unit",
      dataIndex: "Unit",
    },
    {
      title: "Category",
      dataIndex: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Open Duration",
      dataIndex: "OpenDuration",
    },
    {
      title: "Date Entered",
      dataIndex: "DateEntered",
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <div>
      <div className="Edit_Maintenance">
        {hasMaintenancePermission && (
          <>
            <button className="edit-button">Edit</button>
          </>
        )}
      </div>
      <div className="portfolioData-maintenance-card">
        {loading ? (
          <LoaderOverlay desc="Loading..." />
        ) : (
          <>
            <div className="charts-container">
              <div className="portfolioData-maintenance-column-chart">
                <h4>SERVICE REQUESTS BY CATEGORY</h4>
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  height={350}
                />
              </div>
              <div className="portfolioData-maintenance-donut-chart">
                <h4>OPEN DURATION BREAKDOWN</h4>
                <ReactApexChart
                  options={pieChart}
                  series={pieChart.series}
                  type="donut"
                  width={380}
                  height={"100%"}
                />
              </div>
            </div>
            <div className="maintenance-table">
              <h4>Maintenance Work Orders</h4>

              <div className="maintenance_table_wrapper">
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: true,
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Maintenance;
