import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, Button, TextField, CircularProgress} from "@mui/material";

export const TaskDetailsModal = ({isOpen, onClose, task}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Task Details</DialogTitle>
      <DialogContent>
        <p>
          <strong>Task Name:</strong> {task.Name}
        </p>
        <p>
          <strong>Date:</strong> {new Date(task.date).toLocaleDateString()}
        </p>
        <Button onClick={onClose}>Close</Button>
      </DialogContent>
    </Dialog>
  );
};
